import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
import useAuth from "../ahooks/useAuth";
import CustomTable from "../components/CustomTable";
import { BASE_URL } from "../services/BaseUrl";
import ExportCsv from "../components/ExportCsv";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { ButtonTableDelete, ButtonTableEdit, ButtonTableStatus } from "../components/common/ButtonTable";


const Dashboard = () => {
  const { auth } = useAuth()
  const [data, setData] = useState([])

  const [luckyHallValue, setLuckyHallValue] = useState([]);
  const [luckyHallDateRange, setLuckyHallDateRange] = useState([])

  const [prodValue, setProdValue] = useState([]);
  const [prodDateRange, setProdDateRange] = useState([])


  const subHeaderComponentMemo = (data, value, setValue, setDateRange) => {
    return (
      <>
        <div style={{ marginRight: 10 }}>
          <DateRangePicker
            value={value}
            onChange={(v) => {
              if (v == null) {
                setValue([])
                setDateRange([])
                return
              }

              let startDate = moment(v[0])
              let endDate = moment(v[1])
              let range = []
              for (; startDate < endDate;) {
                range.push(startDate.format())

                startDate.add(1, 'day')
              }
              range.push(startDate.format())
              setDateRange(range)
              setValue(v)
            }}
          />
        </div>
        <ExportCsv data={data} />
      </>
    );
  };



  useEffect(() => {
    const data = async () => {
      const res = await axios.get(`${BASE_URL}/content`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`
        }
      })
      setData(res.data.data)
    }

    data()
  }, [auth.token])

  const columnsBanner = [
    {
      name: "Banner",
      selector: (row) => <img height="84px" width="106px" alt={row.nama} src={`${BASE_URL}/${row.gambar}`} />,
    },
    {
      name: "Link Banner",
      sortable: true,
      selector: (row) => row.desc,
      wrap: true
    },
    {
      name: "Create At",
      selector: (row) => row.createdAt ? moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : '',
    },
    {
      name: "Create By",
      selector: (row) => row.id_user.username,
    },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <ButtonTableDelete children='Delete' link={`/dashboard/delete/${row._id}`} />
      ),
    },
  ];
  const columnsLuckyHall = [
    {
      name: "nama",
      sortable: true,
      selector: (row) => row.nama,
    },
    {
      name: "hadiah",
      sortable: true,
      selector: (row) => row.namaBarang,
    },
    {
      name: "lokasi",
      sortable: true,
      selector: (row) => row.lokasi,
      wrap: true
    },
    {
      name: "Gambar",
      selector: (row) => <img height="84px" width="106px" alt={row.name} src={`${BASE_URL}/${row.gambar}`} />,
    },
    {
      name: "Create At",
      selector: (row) => row.createdAt ? moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : '',
      wrap: true
    },
    {
      name: "Create By",
      selector: (row) => row.id_user.username,
    },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <ButtonTableDelete children='Delete' link={`/dashboard/delete/${row._id}`} />
      ),
    },
  ];
  const columnsProd = [
    {
      name: "Nama Product",
      sortable: true,
      selector: (row) => row.namaBarang,
    },
    {
      name: "Photo",
      selector: (row) => <img height="84px" width="106px" alt={row.name} src={`${BASE_URL}/${row.gambar}`} />,
    },
    {
      name: "status",
      cell: (row) => {
        if (row.show === "false") {
          return <div style={{
            borderRadius: 5,
            padding: 8,
            backgroundColor: 'red',
            color: 'white',
            fontWeight: 'bold'
          }}>not active</div>
        } else if (row.show === "true") {
          return <div style={{
            borderRadius: 5,
            padding: 8,
            backgroundColor: 'green',
            color: 'white',
            fontWeight: 'bold'
          }}>active</div>
        }
      }
    },
    {
      name: "Create At",
      sortable: true,
      selector: (row) => row.createdAt ? moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : '',
      wrap: true
    },
    {
      name: "Create By",
      sortable: true,
      selector: (row) => row.id_user.username,
    },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          {/* <NavLink to={`/dashboard/edit-status/${row._id}`}>
            <button style={{ margin: 2, padding: 2, width: 60 }}>Status</button>
          </NavLink> */}
          <ButtonTableStatus
            children='Status'
            link={`/dashboard/edit-status/${row._id}`}
          />
          <ButtonTableDelete
            children='Delete'
            link={`/dashboard/delete/${row._id}`}
          />
        </div>
      ),
    },
  ];
  const columnsContact = [
    {
      name: "nama",
      selector: (row) => row.nama,

    },
    {
      name: "description",
      selector: (row) => row.desc,
      grow: 2
    },
    {
      name: "Last Update",
      selector: (row) => moment(row.updatedAt).format('MMMM Do YYYY, h:mm:ss a'),
      grow: 2
    },
    {
      name: "Update By",
      selector: (row) => row.id_user.username,
    },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <ButtonTableEdit children='Edit' link={`/dashboard/edit/${row._id}`} />
      ),
    },
  ];
  const columnsYoutube = [
    {
      name: "Link",
      selector: (row) => row.desc,
      grow: 2,
      wrap: true
    },
    {
      name: "Create At",
      selector: (row) => moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
      grow: 1.5
    },
    {
      name: "Create By",
      selector: (row) => row.id_user.username,
      grow: 0.5
    },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <ButtonTableDelete children='Delete' link={`/dashboard/delete/${row._id}`} />
      ),
    },
  ];

  const banner = data.filter((item) => item.id_category?.category === "banner")

  const luckyHall = data.filter((item) => item.id_category?.category === "lucky hall")

  const prod = data.filter((item) => item.id_category?.category === "product")
  const contact = data.filter((item) => item.id_category?.category === "contact")
  const youtube = data.filter((item) => item.id_category?.category === "youtube")

  const filterByDateRange = (data, dateRange) => {
    const items = []

    for (const e of dateRange) {
      let date = moment(e)
      for (const d of data) {
        let itemDate = moment(d.createdAt)
        if (itemDate.isSame(date, 'day')) items.push(d)
      }
    }

    return dateRange.length === 0 ? data : items
  }


  return (
    <>
      <CustomTable title='Banner' dataTable={banner} columns={columnsBanner} />
      <CustomTable
        subHeaderComponent={subHeaderComponentMemo(filterByDateRange(luckyHall, luckyHallDateRange), luckyHallValue, setLuckyHallValue, setLuckyHallDateRange)}
        title='Lucky Hall'
        dataTable={filterByDateRange(luckyHall, luckyHallDateRange)}
        columns={columnsLuckyHall}
      />
      <CustomTable
        subHeaderComponent={subHeaderComponentMemo(filterByDateRange(prod, prodDateRange), prodValue, setProdValue, setProdDateRange)}
        title='Product'
        dataTable={filterByDateRange(prod, prodDateRange)}
        columns={columnsProd}
      />
      <CustomTable title='Contact' dataTable={contact} columns={columnsContact} />
      <CustomTable title='Youtube' dataTable={youtube} columns={columnsYoutube} />
    </>
  );
};

export default Dashboard;
