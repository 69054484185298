import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL } from '../services/BaseUrl';
import useAuth from '../ahooks/useAuth';
import jwt_decode from "jwt-decode";
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';


const EditAboutUs = () => {
    const { auth } = useAuth()
    const [about, setAbout] = useState('');
    const [vision, setVision] = useState('');
    const [mission, setMission] = useState('');
    const [loading, setLoading] = useState(false)
    const [getId, setGetId] = useState('')
    const decoded = jwt_decode(auth.token)
    const navigate = useNavigate();
    const { _id } = useParams();

    useEffect(() => {
        const getId = async () => {
            const res = await axios.get(`${BASE_URL}/user/${decoded.adminId}`)
            setGetId(res.data)
        }
        getId()
    }, [])

    const handleCreate = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await axios(`${BASE_URL}/edit-aboutus/` + _id, {
                headers: {
                    Authorization: auth.token,
                },
                method: "PUT",
                data: {
                    id_user: getId.data?._id,
                    about,
                    vision,
                    mission
                },
            })
            setLoading(false)
            Swal.fire(
                'Kamu Berhasil Mengedit data!',
            )
            navigate("/about");
        } catch (error) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    };

    useEffect(() => {
        const fetch = async () => {
            const res = await axios.get(`${BASE_URL}/aboutus`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            }
            )
            setAbout(res.data.data[0].about)
            setVision(res.data.data[0].vision)
            setMission(res.data.data[0].mission)
        }
        fetch()
    }, [auth])
    return (
        <div className='container-editor'>
            <form onSubmit={handleCreate}>
                <h3>Edit About</h3>
                <ReactQuill className='quill' theme="snow" value={about} onChange={setAbout} />
                <h3>Edit vision</h3>
                <ReactQuill className='quill' theme="snow" value={vision} onChange={setVision} />
                <h3>Edit mission</h3>
                <ReactQuill className='quill' theme="snow" value={mission} onChange={setMission} />
                <div className='button'>
                    <button className="deleteBtn" type="submit" >
                        {loading ? "loading" : "Edit"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditAboutUs