import React from "react";
import { useLocation, useParams } from "react-router-dom";
import About from "../page/About";
import Account from "../page/Account";
import Dashboard from "../page/Dashboard";
import Email from "../page/Email";
import Location from "../page/Location";
import Post from "../page/Post";
import AddLocation from "./AddLocation";
import CreateAccount from "./CreateAccount";
import DeleteAccount from "./DeleteAccount";
import DeleteContent from "./DeleteContent";
import DeleteLocation from "./DeleteLocation";
import EditAboutUs from "./EditAboutUs";
import EditContact from "./EditContact";
import EditStatus from "./EditStatus";

const ContentSection = () => {
  const location = useLocation();
  const { _id } = useParams();



  switch (true) {
    case location.pathname === "/account":
      return <Account />;

    case location.pathname === "/":
      return <Dashboard />;

    case location.pathname === "/post":
      return <Post />;

    case location.pathname === "/email":
      return <Email />;

    case location.pathname === "/about":
      return <About />;

    case location.pathname === `/about/edit/${_id}`:
      return <EditAboutUs />;

    case location.pathname === "/location":
      return <Location />;

    case location.pathname === "/location/create-location":
      return <AddLocation />;

    case location.pathname === `/location/delete/${_id}`:
      return <DeleteLocation />;

    case location.pathname === "/account/create-user":
      return <CreateAccount />;

    case location.pathname === `/account/delete/${_id}`:
      return <DeleteAccount />;

    case location.pathname === `/dashboard/delete/${_id}`:
      return <DeleteContent />;

    case location.pathname === `/dashboard/edit/${_id}`:
      return <EditContact />;

    case location.pathname === `/dashboard/edit-status/${_id}`:
      return <EditStatus />;
  }
};

export default ContentSection;
