/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { LeftSideBarContext } from "../page/Page";
import { BurgerButton } from "./BurgerButton";
import { NavLink } from "react-router-dom";
import useAuth from "../ahooks/useAuth";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { BASE_URL } from "../services/BaseUrl";


const LeftSection = () => {
  const { auth } = useAuth()
  const { isShowSidebar, setIsShowSidebar } = useContext(LeftSideBarContext);
  const [activeSidebar, setActiveSidebar] = useState("");
  const [role, setRole] = useState('')

  useEffect(() => {
    const id = jwtDecode(auth.token)
    const fetch = async () => {
      const res = await axios.get(`${BASE_URL}/user/${id.adminId}`)
      setRole(res.data.data.id_role.nama);
    }
    fetch()
  }, [auth])



  // const memoActiveSidebar = useMemo(() => {
  //   return activeSidebar;
  // }, [activeSidebar]);

  return (
    <div
      className={`LeftSidebar_LeftSection LeftSidebar_LeftSection--${isShowSidebar ? "show" : "hide"
        }`}
    >
      <div className="LeftSidebar_LeftSection_topWrapper">
        <BurgerButton onClick={() => setIsShowSidebar(false)} />
      </div>
      <ul className="LeftSidebar_LeftSection_menuWrapper">

        <li>
          <div className="parent_sub_menu">
            <NavLink
              to="/"
              className={`active_sidebar--${activeSidebar === "/" ? "show" : "hide"
                }`}
              onClick={() => setActiveSidebar("/")}
            >
              Dashboard
            </NavLink>
          </div>

        </li>
        <li>
          <NavLink
            to="/post"
            className={`active_sidebar--${activeSidebar === "/post" ? "show" : "hide"
              }`}
            onClick={() => setActiveSidebar("/post")}
          >
            Posts
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/email"
            className={`active_sidebar--${activeSidebar === "/email" ? "show" : "hide"
              }`}
            onClick={() => setActiveSidebar("/email")}
          >
            Email
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/location"
            className={`active_sidebar--${activeSidebar === "/location" ? "show" : "hide"
              }`}
            onClick={() => setActiveSidebar("/location")}
          >
            Location
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={`active_sidebar--${activeSidebar === "/about" ? "show" : "hide"
              }`}
            onClick={() => setActiveSidebar("/about")}
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/account"
            className={`${role !== 'super admin' ? 'a_protect' : ''} active_sidebar--${activeSidebar === "/account" ? "show" : "hide"
              }`}
            onClick={() => {
              setActiveSidebar("/account");
            }}
          >
            Account
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default LeftSection;
