
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useAuth from "../ahooks/useAuth";
import ConsumeSignIn from "../services/Signin";

const Login = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { setAuth } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const date = new moment().format('MMMM Do YYYY, h:mm:ss a')
  const userRef = useRef();


  useEffect(() => {
    userRef.current.focus();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true)
      const payload = {
        username,
        password,
        last_login: date
      };

      const response = await ConsumeSignIn(payload);

      if (response.token !== undefined) {
        const { token } = response
        setAuth({ token })
        navigate('/')
        Swal.fire(
          'Kamu Berhasil Masuk!',
        )
        return true
      }
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Username dan password salah, silahkan coba lagi!',
      })
    } catch (err) {
      if (!err.response) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Network Error!',
        })
      } else if (err.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Missing username or Password!',
        })
      } else if (err.response.status === 401) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'unauthorized!',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'login failed!',
        })
      }
      setLoading(false);
    }



  };

  return (
    <>
      <div className="body-login">
        <div className="ipl-login-box">
          <div className="form-box">
            <h5 className="text-center">Login With Email</h5>
            <form onSubmit={handleSubmit}>
              <div className="login-form">
                <div className="form-group">
                  <div className="form-control">
                    <input
                      type="username"
                      className="form-control"
                      id="input-username"
                      placeholder="Email / username"
                      ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                      required
                    />
                  </div>
                  <div className="form-control">
                    <input
                      type="password"
                      className="form-control"
                      id="input-password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                    />
                  </div>
                  <div className="form-control">
                    <button type="submit" className="button-login-masuk">
                      {loading ? "loading" : "Log in"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>);
};

export default Login;
