import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Page from "./page/Page";
import Login from "./page/Login";
import Post from "./page/Post";
import Account from "./page/Account";
import CreateAccount from "./components/CreateAccount";
import DeleteAccount from "./components/DeleteAccount";
import Dashboard from "./page/Dashboard";
import DeleteContent from "./components/DeleteContent";
import EditContact from "./components/EditContact";
import EditStatus from "./components/EditStatus";
import Email from "./page/Email";
import Location from "./page/Location";
import AddLocation from "./components/AddLocation";
import DeleteLocation from "./components/DeleteLocation";
import About from "./page/About";
import EditAboutUs from "./components/EditAboutUs";

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Page />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard/delete/:_id" element={<DeleteContent />} />
          <Route path="/dashboard/edit/:_id" element={<EditContact />} />
          <Route path="/dashboard/edit-status/:_id" element={<EditStatus />} />
          <Route path="/account" element={<Account />} />
          <Route path="/account/create-user" element={<CreateAccount />} />
          <Route path="/account/delete/:_id" element={<DeleteAccount />} />
          <Route path="/post" element={<Post />} />
          <Route path="/email" element={<Email />} />
          <Route path="/location" element={<Location />} />
          <Route path="/location/create-location" element={<AddLocation />} />
          <Route path="/location/delete/:_id" element={<DeleteLocation />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/edit/:_id" element={<EditAboutUs />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
