import axios from 'axios'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import useAuth from '../ahooks/useAuth'
import { BASE_URL } from '../services/BaseUrl'

const DeleteAccount = () => {
    const { auth } = useAuth()
    const { _id } = useParams()
    const navigate = useNavigate();
    const HandleButton = async (e) => {
        // DELETE request using axios with async/await
        try {
            await axios.delete(`${BASE_URL}/user/delete/` + _id, {
                headers: {
                    Authorization: auth.token,
                },
            });
            Swal.fire(
                'Kamu Berhasil Menghapus Account!',
            )
            navigate("/account");
        } catch (err) {
            alert(err, "Delete failed");
        }
    };
    return (
        <>
            <div className='container-delete'>
                <div className='content-delete'>
                    <h1>Are you sure?</h1>
                    <button
                        onClick={() => {
                            HandleButton();
                        }}
                    >
                        Yes
                    </button>
                    <button onClick={() => navigate("/account")}>No</button>
                </div>
            </div>
        </>
    )
}

export default DeleteAccount