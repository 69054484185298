import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../ahooks/useAuth';
import { BASE_URL } from '../services/BaseUrl';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

const EditContact = () => {
    const { auth } = useAuth();
    const [desc, setDesc] = useState('')
    const [getId, setGetId] = useState('')
    const navigate = useNavigate();
    const { _id } = useParams();
    const [loading, setLoading] = useState(false);
    const decoded = jwt_decode(auth.token)

    useEffect(() => {
        const getId = async () => {
            const res = await axios.get(`${BASE_URL}/user/${decoded.adminId}`)
            setGetId(res.data)
        }
        getId()
    }, [])

    const handleCreate = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await axios(`${BASE_URL}/edit-content/` + _id, {
                headers: {
                    Authorization: auth.token,
                },
                method: "PUT",
                data: {
                    id_user: getId.data?._id,
                    desc,
                },
            })
            setLoading(false)
            Swal.fire(
                'Kamu Berhasil Mengedit data!',
            )
            navigate("/");
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    };
    useEffect(() => {
        const fetch = async () => {
            const res = await axios.get(`${BASE_URL}/content/${_id}`, {
                headers: {
                    Authorization: auth.token,
                },
            });
            setDesc(res.data.desc)
        };
        fetch();
    }, [_id, auth.token]);
    return (
        <div className='create-container'>
            <h5>Edit Content</h5>
            <form onSubmit={handleCreate}>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        id="desc"
                        onChange={(e) => setDesc(e.target.value)}
                        value={desc}
                        required
                    />
                </div>
                <button className="deleteBtn" type="submit">
                    {loading ? "loading" : "Edit"}
                </button>
            </form>
        </div>
    )
}

export default EditContact