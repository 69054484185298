import React from 'react'
import DataTable from 'react-data-table-component'




const CustomTable = ({ dataTable, columns, title, subHeaderComponent }) => {

    // const filteredItems = course.filter
    // ? course.filter(
    //     (item) =>
    //       (item.course &&
    //         item.course.toLowerCase().includes(filterText.toLowerCase())) ||
    //       (item.category &&
    //         item.category.toLowerCase().includes(filterText.toLowerCase()))
    //   )
    // : [];

    return (
        <div >
            <div className='table-container'>
                <DataTable
                    title={title}
                    columns={columns}
                    data={dataTable}
                    subHeader
                    subHeaderComponent={subHeaderComponent}
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                />
            </div>
        </div>
    )
}

export default CustomTable