import React from 'react'
import * as XLSX from 'xlsx';

const ExportCsv = ({ data }) => {
    const handleOnExport = () => {
        const wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(data)

        XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

        XLSX.writeFile(wb, "MyExcel.xlsx")
    }
    return (
        <button onClick={handleOnExport}>
            Export
        </button>
    )
}

export default ExportCsv