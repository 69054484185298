import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../ahooks/useAuth';
import { BASE_URL } from '../services/BaseUrl';
import Swal from 'sweetalert2';

const EditStatus = () => {
    const { auth } = useAuth()
    const { _id } = useParams()
    const navigate = useNavigate();
    const [show, setShow] = useState('')



    useEffect(() => {
        const fetch = async () => {
            const res = await axios.get(`${BASE_URL}/content/` + _id, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            })
            setShow(res.data.show)
        }
        fetch()
    }, [auth, _id])

    const HandleButtonToTrue = async (e) => {
        // DELETE request using axios with async/await

        try {
            await axios(`${BASE_URL}/edit-status/` + _id, {
                headers: {
                    Authorization: auth.token,
                },
                method: "PUT",
                data: {
                    show: 'true',
                },
            });
            Swal.fire(
                'Kamu Berhasil Mengedit Status!',
            )
            navigate("/");
        } catch (err) {
            alert(err, "Delete failed");
        }
    };
    const HandleButtonToFalse = async (e) => {
        // DELETE request using axios with async/await
        try {
            await axios(`${BASE_URL}/edit-status/` + _id, {
                headers: {
                    Authorization: auth.token,
                },
                method: "PUT",
                data: {
                    show: 'false',
                },
            });
            Swal.fire(
                'Kamu Berhasil Mengedit data!',
            )
            navigate("/");
        } catch (err) {
            alert(err, "Delete failed");
        }
    };
    return (
        <>
            <div className='container-delete'>
                <div className='content-delete'>
                    <h1>Are you sure to edit status?</h1>
                    <button
                        onClick={() => {
                            if (show === 'false') {
                                HandleButtonToTrue()
                            } else if (show === 'true') {
                                HandleButtonToFalse()
                            }
                        }}
                    >
                        Yes
                    </button>
                    <button onClick={() => navigate("/")}>No</button>
                </div>
            </div>
        </>
    )
}

export default EditStatus