import React, { useContext } from "react";
import useAuth from '../ahooks/useAuth'
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { ReactComponent as Logout } from "../assets/svg/iconlogout.svg";
import { BurgerButton } from "./BurgerButton";
import { LeftSideBarContext } from "../page/Page";
import Swal from "sweetalert2";


const TopSection = () => {
  const { setIsShowSidebar } = useContext(LeftSideBarContext);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    setAuth({
      access_token: "",
      pass: "",
      user: "",
    });
    navigate("/login");
    Swal.fire(
      'Kamu Berhasil Sign Out!',
    )
  };
  return (
    <div className="LeftSidebar_TopSection">
      <BurgerButton onClick={() => setIsShowSidebar(true)} />

      <Logo width={50} height={50} color="black" />

      <div
        className="style_wrapper_logo"
        role="button"
        onClick={logout}
      >
        <Logout width={30} height={30} color="black" />
        <h4 className="style_text_logo">Logout</h4>
      </div>
    </div>
  );
};

export default TopSection