import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment';
import DataTable from 'react-data-table-component'
import { NavLink } from 'react-router-dom'
import { BASE_URL } from '../services/BaseUrl';


const columns = [
    {
        name: "Nama Lokasi",
        sortable: true,
        selector: (row) => row.nama,

    },
    {
        name: "Detail Lokasi",
        sortable: true,
        selector: (row) => row.detail,

    },
    {
        name: "Link Lokasi",
        sortable: true,
        selector: (row) => row.link,

    },
    {
        name: "Gambar Lokasi",
        selector: (row) => <img height="84px" width="106px" alt={row.name} src={`${BASE_URL}/${row.gambar}`} />,

    },
    {
        name: "Create At",
        selector: (row) => row.createdAt ? moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : '',
        wrap: true
    },
    {
        name: "Create By",
        selector: (row) => row.id_user.username,

    },
    {
        name: 'Action',
        button: true,
        cell: (row) => (
            <NavLink to={`/location/delete/${row._id}`}>
                <button className="button-delete">
                    Delete
                </button >
            </NavLink>
        ),
    },
];

const Location = () => {
    const [loading, setLoading] = useState(false)
    const [allLocation, setAllLocation] = useState('')

    useEffect(() => {
        setLoading(true)
        const fetchAllLocation = async () => {
            const response = await axios.get(`${BASE_URL}/location`)
            setAllLocation(response.data)
            setLoading(false)
        }
        fetchAllLocation()
    }, [])
    return (
        <div className="Account_container">
            <div className="Table_container">
                <div className="Button-create">
                    <NavLink to={`/location/create-location`}>
                        <button>
                            Create Location
                        </button>
                    </NavLink>
                </div>
                <DataTable
                    title='location'
                    columns={columns}
                    data={allLocation.data}
                    progressPending={loading}
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                />
            </div>
        </div>
    )
}

export default Location