import React, { useState } from "react";
import ContentSection from "../components/ContentSection";
import LeftSection from "../components/LeftSection";
import TopSection from "../components/TopSection";

export const LeftSideBarContext = React.createContext({});

const Page = () => {
  const [isShowSidebar, setIsShowSidebar] = useState(true);

  return (
    <LeftSideBarContext.Provider value={{ isShowSidebar, setIsShowSidebar }}>
      <div className="Leftsidebar_container">
        {/* <div
          className={`Leftsidebar_container_overlay`}
          role="button"
          onClick={() => setIsShowSidebar(false)}
        ></div> */}
        <TopSection />
        <ContentSection />
        <LeftSection />
      </div>
    </LeftSideBarContext.Provider>
  );
};

export default Page;
