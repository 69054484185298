import React, { useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../services/BaseUrl';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';


const CreateAccount = () => {
    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await axios.post(`${BASE_URL}/user/create`, {
                headers: {
                    "Content-Type": "application/json"
                },
                id_role: '6361425d7030c6ad77b06838',
                username,
                password,
            });
            setUsername("");
            setPassword("");
            Swal.fire(
                'Kamu Berhasil Membuat Account!',
            )
            navigate("/account");
            setLoading(false);
        } catch (err) {
            alert(err.message);
            setLoading(false);
        }
    };
    return (
        <div className='create-container'>
            <h5>Tambah User</h5>
            <form onSubmit={handleCreate}>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="username"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username.trim()}
                        required
                    />
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                    />
                </div>
                <button className="deleteBtn" type="submit">
                    {loading ? "loading" : "Tambah"}
                </button>
            </form>
        </div>
    )
}

export default CreateAccount