import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment';
import useAuth from '../ahooks/useAuth'
import CustomTable from '../components/CustomTable'
import { BASE_URL } from '../services/BaseUrl'
import { ButtonTableEdit } from '../components/common/ButtonTable';

const About = () => {
    const { auth } = useAuth()
    const [data, setData] = useState([])
    useEffect(() => {
        const dataAboutUs = async () => {
            const res = await axios.get(`${BASE_URL}/aboutus`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            })
            setData(res.data.data)
        }

        dataAboutUs()
    }, [auth.token])

    const columnsSubs = [
        {
            name: "About Us",
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.about }}></div>,
            wrap: true
        },
        {
            name: "Vision",
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.vision }}></div>,
            wrap: true
        },
        {
            name: "Mission",
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.mission }}></div>,
            wrap: true
        },
        {
            name: "Last Update",
            selector: (row) => row.updatedAt ? moment(row.updatedAt).format('MMMM Do YYYY, h:mm:ss a') : '',
            wrap: true
        },
        {
            name: "Action",
            button: true,
            cell: (row) => (
                <ButtonTableEdit children='Edit' link={`/about/edit/${row._id}`} />
            ),
        },
    ];
    return (
        <>
            <CustomTable title='About Us' dataTable={data} columns={columnsSubs} />
        </>
    )
}

export default About