import React, { useState, useEffect } from 'react'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import useAuth from '../ahooks/useAuth';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { BASE_URL } from '../services/BaseUrl';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';


const AddLocation = () => {
    const { auth } = useAuth();
    const navigate = useNavigate()
    const [getId, setGetId] = useState('')
    const [nama, setNama] = useState('')
    const [detail, setdetail] = useState('')
    const [images, setImages] = useState('')
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [link, setLink] = useState('')
    const [loading, setLoading] = useState(false)
    const decoded = jwt_decode(auth.token)


    useEffect(() => {
        const getId = async () => {
            const res = await axios.get(`${BASE_URL}/user/${decoded.adminId}`)
            setGetId(res.data)
        }
        getId()
    }, [])

    useEffect(() => {
        const getLink = async () => {
            let splitUrl = link.split('!3d');
            let latLong = splitUrl[splitUrl.length - 1].split('!4d');
            let longitude;

            if (latLong.indexOf('?') !== -1) {
                longitude = latLong[1].split('\\?')[0];
            } else {
                longitude = latLong[1];
            }
            let latitude = latLong[0];

            setLat(latitude);
            setLng(longitude);
        }
        getLink()
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true)
            const formData = new FormData();
            formData.append("id_user", getId.data?._id);
            formData.append("image", images);
            formData.append("nama", nama);
            formData.append("link", link);
            formData.append("detail", detail);
            formData.append("lat", lat);
            formData.append("long", lng);

            const response = await axios.post(
                `${BASE_URL}/create-location`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );


            if (response.status !== 201) {
                Swal.fire("Maaf gagal untuk menyimpan data, silahkan coba lagi");
            } else {
                Swal.fire("Berhasil menyimpan data");
                navigate('/location')
                setLoading(false)
            }

        } catch (error) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    }

    const handleChangeImage = (e) => {
        e.preventDefault();
        // setImages(e.target.value)
        setImages(e.target.files[0]);

    };
    return (
        <div style={{ marginTop: 50 }} >
            <Form className="post_container" onSubmit={handleSubmit}>
                <Col>
                    <Form.Group controlId="formFileLg" className="mb-3">
                        <Form.Label>Upload Gambar </Form.Label>
                        <Form.Control required type="file" size="lg" onChange={handleChangeImage} />
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                        <Form.Label className="style_title_form_input">
                            Nama Location
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nama Location"
                            className="style_form_input"
                            onChange={(e) => setNama(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                        <Form.Label className="style_title_form_input">
                            Detail Location
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Detail Location"
                            className="style_form_input"
                            onChange={(e) => setdetail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                        <Form.Label className="style_title_form_input">
                            Link Location
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nama Location"
                            className="style_form_input"
                            onChange={(e) => setLink(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Button
                    variant="primary"
                    type="submit"
                    className="style_button_submit"

                >
                    {loading ? 'Loading..' : 'Simpan'}
                </Button>
            </Form>
        </div>
    )
}

export default AddLocation