import React from 'react'
import { NavLink } from 'react-router-dom'

export const ButtonTableDelete = ({ children, link }) => {
    return (
        <NavLink
            to={link}
            style={{
                textDecoration: 'none'
            }}
            className='button-table-delete'
        >
            <button
            >
                {children}
            </button>
        </NavLink>
    )
}

export const ButtonTableStatus = ({ children, link }) => {
    return (
        <NavLink
            to={link}
            style={{
                textDecoration: 'none',
                paddingBottom: 10
            }}
            className='button-table-status'
        >
            <button
            >
                {children}
            </button>
        </NavLink>
    )
}

export const ButtonTableEdit = ({ children, link }) => {
    return (
        <NavLink
            to={link}
            style={{
                textDecoration: 'none',
                paddingBottom: 10
            }}
            className='button-table-edit'
        >
            <button
            >
                {children}
            </button>
        </NavLink>
    )
}

