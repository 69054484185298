import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Swal from "sweetalert2";
import useAuth from "../ahooks/useAuth";
import { BASE_URL } from "../services/BaseUrl";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";


const Post = () => {
  const { auth } = useAuth();
  const navigate = useNavigate()
  const [getId, setGetId] = useState('')
  const [images, setImages] = useState();
  const [description, setDescription] = useState('')
  const [selected, setSelected] = useState("");
  const [namaHadiah, setNamaHadiah] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("")
  const [namaPemenang, setNamaPemenang] = useState("");
  const [namaProduk, setNamaProduk] = useState("");
  const [lokasi, setLokasi] = useState("");
  const [loading, setLoading] = useState(false)
  const getUrlFromImage = images === undefined ? '' : URL.createObjectURL(images)
  const decoded = jwt_decode(auth.token)

  useEffect(() => {
    const getId = async () => {
      const res = await axios.get(`${BASE_URL}/user/${decoded.adminId}`)
      setGetId(res.data)
    }
    getId()
  }, [])

  const options = [
    { name: "banner" },
    { name: "lucky hall" },
    { name: "product" },
    { name: "youtube" },
  ];


  useEffect(() => {
    setSelected(options[0].name);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (selected === "banner") {
      try {
        const formData = new FormData();
        formData.append("id_category", "63528ba98b835866467f37ff");
        formData.append("id_user", getId.data?._id);
        formData.append("image", images);
        formData.append("desc", description);

        const response = await axios.post(
          `${BASE_URL}/create-content`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );


        if (response.status !== 201) {
          Swal.fire("Maaf gagal untuk menyimpan data, silahkan coba lagi");
          setLoading(false)
        } else {
          Swal.fire("Berhasil menyimpan data");
          navigate('/')
          setLoading(false)
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
        setLoading(false)
      }
    } else if (selected === "lucky hall") {
      try {
        const formData = new FormData();
        formData.append("id_category", "63528be48b835866467f3800");
        formData.append("id_user", getId.data?._id);
        formData.append("image", images);
        formData.append("nama", namaPemenang);
        formData.append("namaBarang", namaHadiah);
        formData.append("lokasi", lokasi);

        const response = await axios.post(
          `${BASE_URL}/create-content`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );


        if (response.status !== 201) {
          Swal.fire("Maaf gagal untuk menyimpan data, silahkan coba lagi");
          setLoading(false)
        } else {
          Swal.fire("Berhasil menyimpan data");
          navigate("/");
          setLoading(false)
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
        setLoading(false)
      }
    } else if (selected === "product") {
      try {
        const formData = new FormData();
        formData.append("id_category", "63528bfc8b835866467f3801");
        formData.append("id_user", getId.data?._id);
        formData.append("image", images);
        formData.append("namaBarang", namaProduk);
        // formData.append("show")

        const response = await axios.post(
          `${BASE_URL}/create-content`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );


        if (response.status !== 201) {
          Swal.fire("Maaf gagal untuk menyimpan data, silahkan coba lagi");
          setLoading(false)
        } else {
          Swal.fire("Berhasil menyimpan data");
          navigate('/')
          setLoading(false)
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
        setLoading(false)
      }
    } else if (selected === "youtube") {
      try {
        const response = await axios.post(`${BASE_URL}/create-youtube`,
          {
            id_category: "63628365e05e198074206591",
            id_user: getId.data?._id,
            desc: urlYoutube
          }, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          }
        });


        if (response.status !== 201) {
          Swal.fire("Maaf gagal untuk menyimpan data, silahkan coba lagi");
          setLoading(false)
        } else {
          Swal.fire("Berhasil menyimpan data");
          navigate('/')
          setLoading(false)
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
        setLoading(false)
      }
    }
  };

  const handleChangeImage = (e) => {
    e.preventDefault();
    setImages(e.target.files[0]);
  };

  const handleChangeOptions = (e) => {
    setSelected(e.target.value);
  };

  return (
    <Form className="post_container">
      <Row className="mb-5 w-50 h-50">
        <Form.Group as={Col} controlId="formGridState">
          <Form.Select
            defaultValue="Pilih ..."
            className="style_dropdown"
            onChange={handleChangeOptions}
          >
            {options.map((o) => (
              <option key={o.name} value={o.name}>
                {o.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>

      {selected === "product" && (
        <Row className="mb-3 w-50">
          <Form.Group controlId="formFileLg" className="mb-3">
            <Form.Label>Upload gambar</Form.Label>
            <Form.Control required type="file" size="lg" onChange={handleChangeImage} />
          </Form.Group>

          {images !== undefined && (
            <Form.Group
              controlId="formImage"
              className="mb-3 style_wrapper_image"
            >
              <img
                src={getUrlFromImage}
                width={500}
                height={300}
                className="style_image_child"
                alt=""
              />
            </Form.Group>
          )}
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label className="style_title_form_input">
              Nama Produk
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukan nama hadiah"
              className="style_form_input"
              onChange={(e) => setNamaProduk(e.target.value)}
            />
          </Form.Group>
        </Row>
      )}

      {selected === "lucky hall" && (
        <Row className="mb-3 w-50">
          <Form.Group controlId="formFileLg" className="mb-3">
            <Form.Label>Upload gambar</Form.Label>
            <Form.Control required type="file" size="lg" onChange={handleChangeImage} />
          </Form.Group>

          {images !== undefined && (
            <Form.Group
              controlId="formImage"
              className="mb-3 style_wrapper_image"
            >
              <img
                src={getUrlFromImage}
                width={500}
                height={300}
                className="style_image_child"
                alt=""
              />
            </Form.Group>
          )}

          <Col>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label className="style_title_form_input">
                Nama Pemenang
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan nama pemenang"
                className="style_form_input"
                onChange={(e) => setNamaPemenang(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label className="style_title_form_input">
                Nama Hadiah
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan nama hadiah"
                className="style_form_input"
                onChange={(e) => setNamaHadiah(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label className="style_title_form_input">Lokasi</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan Lokasi"
                className="style_form_input"
                onChange={(e) => setLokasi(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      )}

      {selected === "banner" && (
        <Row className="mb-3 w-50">
          <Form.Group controlId="formFileLg" className="mb-3">
            <Form.Label className="style_title_form_input">Upload gambar</Form.Label>
            <Form.Control required type="file" size="lg" onChange={handleChangeImage} />
          </Form.Group>


          {images !== undefined && (
            <Form.Group
              controlId="formImage"
              className="mb-3 style_wrapper_image"
            >
              <img
                alt=""
                src={getUrlFromImage}
                width={500}
                height={300}
                className="style_image_child"
              />
            </Form.Group>

          )}
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label className="style_title_form_input">
              Link Banner
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukan link (Optional)"
              className="style_form_input"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Row>
      )}

      {selected === "youtube" && (
        <Row className="mb-3 w-50">
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label className="style_title_form_input">
              Url Youtube
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukan url youtube example: https://www.youtube.com/embed/embed-code"
              className="style_form_input"
              onChange={(e) => setUrlYoutube(e.target.value)}
            />
          </Form.Group>

        </Row>
      )}

      <Button
        variant="primary"
        type="submit"
        className="style_button_submit"
        onClick={handleSubmit}
      >
        {loading ? 'Loading...' : 'Simpan'}
      </Button>
    </Form>
  );
};

export default Post;

