import axios from 'axios';
import React, { useState, useEffect } from 'react'
import moment from 'moment';
import useAuth from '../ahooks/useAuth';
import CustomTable from '../components/CustomTable'
import { BASE_URL } from '../services/BaseUrl';
import { DateRangePicker } from 'rsuite';
import ExportCsv from '../components/ExportCsv';
import { AiFillFile } from 'react-icons/ai'

const Email = () => {
    const { auth } = useAuth()
    const [dataSubs, setDataSubs] = useState([])
    const [subsValue, setSubsValue] = useState([]);
    const [subsDateRange, setSubsDateRange] = useState([])
    const [dataEmail, setDataEmail] = useState([])
    const [emailValue, setEmailValue] = useState([]);
    const [emailDateRange, setEmailDateRange] = useState([])
    useEffect(() => {
        const dataSubs = async () => {
            const res = await axios.get(`${BASE_URL}/get-send-subs`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            })
            setDataSubs(res.data.data)
        }
        const dataEmail = async () => {
            const res = await axios.get(`${BASE_URL}/get-send-email`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            })
            setDataEmail(res.data.data)
        }
        dataSubs()
        dataEmail()
    }, [auth.token])

    const columnsSubs = [
        {
            name: "Email",
            sortable: true,
            selector: (row) => row.email,
        },
        {
            name: "Create At",
            selector: (row) => row.createdAt ? moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : '',
            wrap: true
        },

    ];
    const columnsEmail = [
        {
            name: "Nama",
            sortable: true,
            selector: (row) => row.nama,
            wrap: true
        },
        {
            name: "Brand",
            sortable: true,
            selector: (row) => row.brand,
            wrap: true,
        },
        {
            name: "Category",
            sortable: true,
            selector: (row) => row.category,
            wrap: true,
        },
        {
            name: "Email",
            sortable: true,
            selector: (row) => row.email,
            wrap: true
        },
        {
            name: "No. telpon",
            sortable: true,
            selector: (row) => row.no_telp,
            grow: 3
        },
        {
            name: "Address",
            sortable: true,
            wrap: true,
            selector: (row) => row.address,
            grow: 2
        },
        {
            name: "Description",
            wrap: true,
            selector: (row) => row.description
        },
        {
            name: "File",
            selector: (row) =>
                <div >
                    {
                        row.file ?
                            <a href={row.file} className='' target='_blank' rel="noreferrer">
                                <AiFillFile width={15} height={15} className='text-black' />
                            </a>
                            : ""
                    }
                </div>


        },
        {
            name: "Create At",
            selector: (row) => row.createdAt ? moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : '',
            wrap: true
        },
    ];


    const subHeaderComponentMemo = (data, value, setValue, setDateRange) => {
        return (
            <>
                <div style={{ marginRight: 10 }}>
                    <DateRangePicker
                        value={value}
                        onChange={(v) => {
                            if (v == null) {
                                setValue([])
                                setDateRange([])
                                return
                            }

                            let startDate = moment(v[0])
                            let endDate = moment(v[1])
                            let range = []
                            for (; startDate < endDate;) {
                                range.push(startDate.format())

                                startDate.add(1, 'day')
                            }
                            range.push(startDate.format())
                            setDateRange(range)
                            setValue(v)
                        }}
                    />
                </div>
                <ExportCsv data={data} />
            </>
        );
    };


    const filterByDateRange = (data, dateRange) => {
        const items = []

        for (const e of dateRange) {
            let date = moment(e)
            for (const d of data) {
                let itemDate = moment(d.createdAt)
                if (itemDate.isSame(date, 'day')) items.push(d)
            }
        }

        return dateRange.length === 0 ? data : items
    }

    return (
        <>
            <CustomTable
                subHeaderComponent={subHeaderComponentMemo(filterByDateRange(dataSubs, subsDateRange), subsValue, setSubsValue, setSubsDateRange)}
                title='Subscribe'
                dataTable={filterByDateRange(dataSubs, subsDateRange)}
                columns={columnsSubs}
            />
            <CustomTable
                subHeaderComponent={subHeaderComponentMemo(filterByDateRange(dataEmail, emailDateRange), emailValue, setEmailValue, setEmailDateRange)}
                title='PIC'
                dataTable={filterByDateRange(dataEmail, emailDateRange)}
                columns={columnsEmail}
            />
        </>
    )
}

export default Email