import React from 'react'

export const BurgerButton = ({ onClick }) => {
  return (
    <div className="LeftSidebar_BurgerButton" role="button" onClick={onClick}>
        <i></i>
        <i></i>
        <i></i>
    </div>
  )
}