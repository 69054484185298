import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { BASE_URL } from "../services/BaseUrl";
import { ButtonTableDelete } from "../components/common/ButtonTable";

const columns = [
  {
    name: "Username",
    sortable: true,
    selector: (row) => row.username,
  },
  {
    name: "Last Login",
    sortable: true,
    selector: (row) => row.last_login,
  },
  {
    name: 'Action',
    button: true,
    cell: (row) => (
      <ButtonTableDelete children='Delete' link={`/account/delete/${row._id}`} />
    ),
  },
];
const Account = () => {
  const [loading, setLoading] = useState(false)
  const [allUser, setAllUser] = useState('')

  useEffect(() => {
    setLoading(true)
    const fetchAllUser = async () => {
      const response = await axios.get(`${BASE_URL}/user`)
      setAllUser(response.data)
      setLoading(false)
    }
    fetchAllUser()
  }, [])


  return (
    <div className="Account_container">
      <div className="Table_container">
        <div className="Button-create">
          <NavLink to={`/account/create-user`}>
            <button>
              Create Account
            </button>
          </NavLink>
        </div>
        <DataTable
          columns={columns}
          data={allUser.data}
          progressPending={loading}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
        />
      </div>
    </div>
  );
};

export default Account;
